<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card class="pr-4">
      <v-card-title> Update Debit/Credit Card Details </v-card-title>
      <v-card-text>
        <v-text-field
          required
          :rules="[(v) => !!v || 'Card no is required']"
          v-model="card.card_number"
          label="Card No"
          filled
          dense
        ></v-text-field>

        <v-text-field
          required
          :rules="[(v) => !!v || 'Name is required']"
          v-model="card.name"
          label="Name on Card"
          filled
          dense
        ></v-text-field>
        <v-row>
          <v-col cols="12" sm="6">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  required
                  :rules="[(v) => !!v || 'Date is required']"
                  filled
                  dense
                  v-model="card.expiry_date"
                  label="Expiry Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="card.expiry_date"
                type="month"
                no-title
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(card.expiry_date)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              required
              :rules="[(v) => !!v || 'CVV is required']"
              v-model="card.card_code"
              label="Card Security Code"
              :counter="3"
              filled
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="success" @click="updateButton()">Update </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      card: {
        card_number: "",
        name: "",
        expiry_date: "",
        card_code: "",
        form_type: "update_subs_card_detail",
      },

      date: new Date().toISOString().substr(0, 7),
      menu: false,
      valid: true,
    };
  },
  methods: {
    ...mapActions(["updateCard"]),
    updateButton() {
      if (this.$refs.form.validate())
        this.updateCard({ token: this.userToken, data: this.card })
          .then((res) => {
            const updated_data = res.data
            // Showing error message
            let error_list = []
            for(let subscription_id in updated_data){
              let row_data = updated_data[subscription_id]
              if(!row_data['type']){
                error_list.push(row_data)
                this.$toasted.show(`Subsription Id: ${subscription_id} - ${row_data.error_msg}`, {
                  type: "error",
                  duration: 3000,
                  position: "top-center",
                  theme: "bubble",
                  icon: "mdi-account-check",
                  iconPack: "mdi",
                });
              }
            }

            if(error_list.length < Object.keys(updated_data).length){
              this.$toasted.show(`Card details updated for other subscriptions`, {
                type: "success",
                duration: 3000,
                position: "top-center",
                theme: "bubble",
                icon: "mdi-account-check",
                iconPack: "mdi",
              });
            }
            
          })
          .catch((err) => {
            this.$toasted.show(`${err.response.data}`, {
              type: "error",
              duration: 3000,
              position: "top-center",
              theme: "bubble",
              icon: "mdi-account-check",
              iconPack: "mdi",
            });
          });
    },
  },

  computed: {
    ...mapGetters(["userToken"]),
  },
};
</script>

<style>
</style>