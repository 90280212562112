export const couponInitialData = () => {
  return {
    code: "",
    type: "A", // Can be P - Perc or A - Amount
    perc: 0,
    amount: 0,
    stat: 1,
    remarks: "",
    max_usage: 1,
  };
};
