<template>
  <v-container>
    <app-bar></app-bar>

    <v-row>
      <!-- Grade Card -->
      <!-- <v-col cols="12" sm="6" class="mt-4">
        <grade-card></grade-card>
      </v-col> -->

      <v-col cols="12" sm="6" class="mt-4">
        <v-row>
          <v-col cols="12">
            <!-- User Detail -->
            <user-detail></user-detail
          ></v-col>
          <v-col cols="12">
            <!-- Subscription details -->
            <subscription-detail></subscription-detail
          ></v-col>
        </v-row>
      </v-col>

      <!-- Update card       -->
      <v-col cols="12" sm="6" v-masonry-tile class="item">
        <card-update> </card-update>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CardUpdate from "@/components/Home/CardUpdate";
// import GradeCard from "@/components/Home/GradeCard";
import SubscriptionDetail from "@/components/Home/SubscriptionDetail";
import UserDetail from "@/components/Home/UserDetail";
import AppBar from "@/components/AppBar";
export default {
  components: {
    CardUpdate,
    // GradeCard,
    AppBar,
    UserDetail,
    SubscriptionDetail,
  },
  methods: {},
  computed: {},
};
</script>
