<template>
    <v-form>
        <div class="text-center">
          <v-progress-circular
            v-if="isLoading"
            class="mt-8"
            color="#fd9910"
            indeterminate
            rounded
            height="6"
          ></v-progress-circular>
        </div>
        <v-container>
            <h2 class="pt-8 pb-4">{{id ? "Edit" : "Create"}} Coupon</h2>
        <v-row>
            <v-col cols="12" md="6">
            <v-text-field v-model="coupon.code" label="Coupon Code" required></v-text-field>
            </v-col>

            <v-col class="d-flex" cols="12" sm="6">
                <v-select :items="discountTypes" label="Discount Type" v-model="coupon.type"></v-select>
            </v-col>
        </v-row>
        <v-row>
            <v-col v-if="coupon.type == 'P'" cols="12" md="6">
            <v-text-field v-model="coupon.perc" type="number" label="Percentage (in %)" required></v-text-field>
            </v-col>

            <v-col v-else-if="coupon.type == 'A'" cols="12" md="6">
            <v-text-field v-model="coupon.amount" type="number" label="Amount (in $)" required></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
            <v-text-field v-model="coupon.max_usage" type="number" label="Max Usage" required></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="12">
                <v-textarea name="input-7-1" label="Remarks" v-model="coupon.remarks"></v-textarea>
            </v-col>
        </v-row>
        <div class="text-center">
            <v-btn @click="proceed()" color="accent" elevation="2">{{id ? "Update" : "Create"}}</v-btn>
        </div>
        </v-container>
  </v-form>
</template>
<script>
import {mapActions, mapGetters} from "vuex"
import {couponInitialData} from "@/components/Coupon/utils"
export default {
    name : "set-coupon",
    props : {
        set_coupon_type : {
            type : String,
            required : true
        },
        id : {
            type : String
        }
    },
    data() {
        return {
            isLoading : false,
            discountTypes : [{ text : "Percentage", value : "P"}, { text : "Amount", value : "A"}],
            coupon : couponInitialData()
        }
    },
    methods : {
        ...mapActions(["loadCouponDetails", "createCoupon", "updateCoupon", "emptyCurrentCoupon"]),
        proceed(){
            if(this.id){
                this.updateCoupon({
                    token : this.userToken, 
                    detail : {form_type : "update_coupon", id : this.id, ...this.coupon}
                }).then(res => {
                    this.$toasted.show("Coupon updated", {
                        type: "success",
                        duration: 5000,
                        position: "top-center",
                        theme: "bubble",
                        icon: "mdi-clipboard-check",
                        iconPack: "mdi",
                    });
                }).catch(err => {
                    this.$toasted.show(err.response.data, {
                        type: "error",
                        duration: 5000,
                        position: "top-center",
                        theme: "bubble",
                        icon: "mdi-clipboard-check",
                        iconPack: "mdi",
                    });
                })
            }else{
                this.createCoupon({
                    token : this.userToken, 
                    detail : {form_type : "insert_coupon", ...this.coupon}
                }).then(res => {
                    this.$toasted.show("Coupon created successfully", {
                        type: "success",
                        duration: 5000,
                        position: "top-center",
                        theme: "bubble",
                        icon: "mdi-clipboard-check",
                        iconPack: "mdi",
                    });

                    this.coupon = couponInitialData()
                }).catch(err => {
                    this.$toasted.show(err.response.data, {
                        type: "error",
                        duration: 5000,
                        position: "top-center",
                        theme: "bubble",
                        icon: "mdi-clipboard-check",
                        iconPack: "mdi",
                    });
                })
            }
        },
        loadComponentData(){
            if(this.id){
                this.isLoading = true
                this.loadCouponDetails({ token: this.userToken, id : this.id }).then((data) => {
                    for(let key in this.coupon){
                        this.coupon[key] = data[key]
                    }
                }).catch(err => {
                    this.$toasted.show(error, {
                        type: "error",
                        duration: 5000,
                        position: "top-center",
                        theme: "bubble",
                        icon: "mdi-clipboard-check",
                        iconPack: "mdi",
                    });
                }).finally(() => this.isLoading = false)
            }else{
                this.emptyCurrentCoupon()
                this.coupon = couponInitialData()
            }
        }
    },
    computed : {
        ...mapGetters(["fetchCurrentCoupon", "userToken"])
    },
    created(){
        this.loadComponentData()
    },
    watch : {
        id : function(new_data){
            this.loadComponentData()
        }
    }

}
</script>