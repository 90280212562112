<template>
  <v-container fluid>
    <app-bar></app-bar>
    <v-row>
      <v-col cols="12" md="8">
        <h2>Coupons</h2>
      </v-col>
      <v-col cols="12" md="4">
        <v-btn @click="createThisCoupon()">Create Coupon</v-btn>
      </v-col>
    </v-row>
    
    <v-data-table
      :headers="headers"
      :items="fetchCoupons"
      :items-per-page="5"
      class="elevation-1"
    >
      <template v-slot:[`item.id`]="{ item }">
        <v-icon @click="viewDialog(item.id)">mdi-pencil </v-icon>
        <v-icon @click="deleteThisCoupon(item.id)">mdi-delete </v-icon>
      </template>
      <template v-slot:[`item.type`]="{ item }">
        {{item.type == "P" ? "Percentage" : "Amount"}}
      </template>
      <template v-slot:[`item.stat`]="{ item }">
        <v-icon :color="`${(item.stat == 1) ? 'green' : 'red'} darken-2`">{{ (item.stat == 1) ? "mdi-check" : "mdi-close" }}</v-icon>
      </template>
    </v-data-table>

    <!-- Dialog box  -->
    <v-dialog transition="dialog-bottom-transition" v-model="dialog" persistent>
      <v-card tile>
        <set-coupon :set_coupon_type="set_coupon_type" :id="current_coupon" />          
        <v-card-actions class="justify-end">
          <v-btn text @click="closeDialog()" color="error">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AppBar from "@/components/AppBar";
import SetCoupon from "@/components/Coupon/SetCoupon";
export default {
  data() {
    return {
      current_coupon : null,
      dialog: false,
      isLoading: false,
      set_coupon_type : "add",
      headers: [
        { text: "Code", value: "code" },
        { text: "Type", value: "type" },
        { text: "Percentage", value: "perc" },
        { text: "Amount", value: "amount" },
        { text: "Max usages", value: "max_usage" },
        { text: "Status", value: "stat" },
        { text: "Action", value: "id" },
      ],
    };
  },
  components: {
    AppBar, SetCoupon
  },
  methods: {
    ...mapActions(["loadCoupons", "createCoupon", "loadCouponDetails", "deleteCoupon"]),
    createThisCoupon(){
      this.dialog = true
      this.current_coupon = null
      this.set_coupon_type = "add"
    },
    viewDialog(id) {
      this.dialog = true;
      this.current_coupon = id
      this.set_coupon_type = "edit"
    },
    closeDialog(){
      this.dialog = false;
      // this.current_coupon = null
    },
    deleteThisCoupon(id){
      if(confirm("Are you sure want to delete this coupon?")){
        this.deleteCoupon({
          token: this.userToken,
          detail : {
            form_type : "delete_coupon",
            id
          }
        }).then((data) => {
          this.$toasted.show(data.message, {
            type: "success",
            duration: 5000,
            position: "top-center",
            theme: "bubble",
            icon: "mdi-clipboard-check",
            iconPack: "mdi",
          });
        }).catch((err) => {
          this.$toasted.show(err.response.data, {
            type: "success",
            duration: 5000,
            position: "top-center",
            theme: "bubble",
            icon: "mdi-clipboard-check",
            iconPack: "mdi",
          });
        });
      }
    },
  },
  computed: {
    ...mapGetters(["userToken", "fetchCoupons", "fetchCurrentCoupon"]),
  },
  async created() {
    this.$vloading.show();
    await this.loadCoupons({ token : this.userToken })
    this.$vloading.hide();
  },
};
</script>

<style>
</style>