<template>
  <v-card class="pr-4" v-if="allDetails">
    <v-card-title> Billing Details </v-card-title>
    <v-card-text>
      <v-row class="mt-2">
        <v-col cols="12" sm="4" class="py-0"> Company: </v-col>
        <v-col cols="12" sm="8" align="left" class="py-0">
          <p>{{ allDetails.company }}</p>
        </v-col>
      </v-row>
      <v-row class="mt-2">
        <v-col cols="12" sm="4" class="py-0"> Address: </v-col>
        <v-col cols="12" sm="8" align="left" class="py-0">
          <p style="white-space: pre">{{ allDetails.address }}</p>
        </v-col>
      </v-row>
      <v-row class="mt-2">
        <v-col cols="12" sm="4" class="py-0"> State: </v-col>
        <v-col cols="12" sm="8" align="left" class="py-0">
          <p style="white-space: pre">{{ allDetails.state }}</p>
        </v-col>
      </v-row>
      <v-row class="mt-2">
        <v-col cols="12" sm="4" class="py-0"> City: </v-col>
        <v-col cols="12" sm="8" align="left" class="py-0">
          <p>{{ allDetails.city }}</p>
        </v-col>
      </v-row>
      <v-row class="mt-2">
        <v-col cols="12" sm="4" class="py-0"> Zip: </v-col>
        <v-col cols="12" sm="8" align="left" class="py-0">
          <p>{{ allDetails.zip }}</p>
        </v-col>
      </v-row>
      <v-row class="mt-2">
        <v-col cols="12" sm="4" class="py-0"> Country: </v-col>
        <v-col cols="12" sm="8" align="left" class="py-0">
          <p>{{ allDetails.country }}</p>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  components: {},
  methods: {
    ...mapActions([""]),
    logout() {
      this.logoutUser();
    },
  },
  computed: {
    ...mapGetters(["allDetails"]),
  },
};
</script>
