<template>
  <v-card class="pr-4" v-if="latestSubscriptionDetails">
    <v-card-title> Latest Subscription Details </v-card-title>
    <v-card-text>
      <v-row class="mt-2">
        <v-col cols="12" sm="4" class="py-0"> Invoice Number: </v-col>
        <v-col cols="12" sm="8" align="left" class="py-0">
          <p>{{ latestSubscriptionDetails.invoice_no }}</p>
        </v-col>
      </v-row>
      <v-row class="mt-2">
        <v-col cols="12" sm="4" class="py-0"> Ref Id: </v-col>
        <v-col cols="12" sm="8" align="left" class="py-0">
          <p>{{ latestSubscriptionDetails.refId }}</p>
        </v-col>
      </v-row>
      <v-row class="mt-2">
        <v-col cols="12" sm="4" class="py-0"> Subscription Id: </v-col>
        <v-col cols="12" sm="8" align="left" class="py-0">
          <p>{{ latestSubscriptionDetails.subscription_id != '' ? latestSubscriptionDetails.subscription_id   : '-' }} </p>
        </v-col>
      </v-row>
      <v-row class="mt-2">
        <v-col cols="12" sm="4" class="py-0"> Time Stamp: </v-col>
        <v-col cols="12" sm="8" align="left" class="py-0">
          <p>{{ latestSubscriptionDetails.timestamp }}</p>
        </v-col>
      </v-row>
      <v-row class="mt-2">
        <v-col cols="12" sm="4" class="py-0"> Monthly Amount: </v-col>
        <v-col cols="12" sm="8" align="left" class="py-0">
          <p>$ {{ latestSubscriptionDetails.monthly_amount }}</p>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  methods: {
    ...mapActions(["fetchLatestSubscription"]),
  },
  computed: {
    ...mapGetters(["userToken", "latestSubscriptionDetails"]),
  },
  async created() {
    this.$vloading.show();
    await this.fetchLatestSubscription(this.userToken);
    this.$vloading.hide();
  },
};
</script>

<style>
</style>