<template>
  <admin-home v-if="type == 'A'"></admin-home>
  <parent-home v-else></parent-home>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AdminHome from "./AdminHome.vue";
import ParentHome from "./ParentHome.vue";
export default {
  components: {
    AdminHome,
    ParentHome,
  },
  methods: {
    ...mapActions([""]),
  },
  computed: {
    ...mapGetters(["type"]),
  },
};
</script>
