<template>
  <v-container fluid>
    <app-bar></app-bar>
    <v-row>
      <v-col cols="12" md="4">
        <v-btn @click="$router.push('coupon')">Coupons</v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="users"
      :items-per-page="5"
      class="elevation-1"
    >
      <template v-slot:[`item.id`]="{ item }">
        <v-icon @click="viewDialog(item.id)">mdi-eye </v-icon>
      </template>
      <template v-slot:[`item.timestamp`]="{ item }">
        {{ $common.formatDate(item.timestamp) }}
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-switch
          false-value="0"
          true-value="1"
          :input-value="item.status"
          @change="(status) => statusChange({ status, userid: item.id })"
        ></v-switch>
      </template>
    </v-data-table>

    <!-- Dialog box  -->
    <v-dialog transition="dialog-bottom-transition" v-model="dialog" persistent>
      <v-card tile>
        <!-- Table of order  -->
        <div class="text-center" v-if="!transaction">
          <v-progress-circular
            v-if="isLoading"
            class="mt-8"
            color="#fd9910"
            indeterminate
            rounded
            height="6"
          ></v-progress-circular>
          <h3 class="pt-8" v-else>Empty</h3>
        </div>
        <div v-else>
          <div v-for="(value, key) in transaction.subscriptions" :key="key">
            <v-card-title style="display: flex; justify-content: space-between">
              <div>
                Subscription ID: {{ key }} <b>({{ value.main.status }})</b>
              </div>

              <v-btn
                v-if="value.main.status == 'active'"
                depressed
                color="error"
                @click="cancelSubButton(key)"
              >
                Cancel Subscription
              </v-btn>
            </v-card-title>

            <v-card-text>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Payment Number</th>
                      <th class="text-left">Transaction Id</th>
                      <th class="text-left">Submit Time</th>
                      <th class="text-left">Response</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="transaction_detail in value.transactions"
                      :key="transaction_detail.transaction_id"
                    >
                      <td>{{ transaction_detail.paynum }}</td>
                      <td>{{ transaction_detail.transaction_id }}</td>
                      <td>
                        {{
                          $common.formatDate(
                            transaction_detail.submit_time.date
                          )
                        }}
                      </td>
                      <td>{{ transaction_detail.response }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </div>
          <v-card-actions class="justify-end">
            <v-btn text @click="closeDialog()" color="error">Close</v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AppBar from "@/components/AppBar";
export default {
  data() {
    return {
      current_user : null,
      users: [],
      transaction: null,
      dialog: false,
      isLoading: false,
      isStatus: false,
      headers: [
        {
          text: "Parent Name",
          align: "start",
          sortable: false,
          value: "parent_name",
        },
        { text: "Email", value: "email" },
        { text: "Mobile", value: "mobile" },
        { text: "Registered at", value: "timestamp" },
        { text: "Address", value: "address" },
        { text: "City", value: "city" },
        { text: "Zip", value: "zip" },
        { text: "Status", value: "status", align: "center" },
        { text: "Action", value: "id" },
      ],
    };
  },
  components: {
    AppBar,
  },
  methods: {
    ...mapActions(["fetchAllUsers", "fetchTransaction", "userStatusToggle", "cancelSubscription"]),
    async viewDialog(id) {
      this.dialog = true;
      this.isLoading = true;
      this.current_user = id
      try {
        await this.fetchTransaction({ token: this.userToken, id }).then(
          (res) => {
            this.isLoading = false;
            this.transaction = res.data;
          }
        );
      } catch (error) {
        this.$toasted.show(error, {
          type: "error",
          duration: 5000,
          position: "top-center",
          theme: "bubble",
          icon: "mdi-clipboard-check",
          iconPack: "mdi",
        });
      }
    },
    closeDialog(){
      this.dialog = false;
      this.transaction = null;
      this.current_user = null
    },
    async statusChange({ status, userid }) {
      try {
        this.userStatusToggle({
          token: this.userToken,
          status: status,
          user_id: userid,
        }).then((res) => {
          this.$toasted.show(res.message, {
            type: "success",
            duration: 5000,
            position: "top-center",
            theme: "bubble",
            icon: "mdi-clipboard-check",
            iconPack: "mdi",
          });
        });
      } catch (error) {
        this.$toasted.show(error, {
          type: "error",
          duration: 5000,
          position: "top-center",
          theme: "bubble",
          icon: "mdi-clipboard-check",
          iconPack: "mdi",
        });
      }
    },
    cancelSubButton(subscription) {
      if(confirm("Are you sure want to cancel this subscription")){
        this.cancelSubscription({
          token : this.userToken,
          data : {
            userid : this.current_user,
            subscription, 
            form_type : "cancel_subscription"
          }
        }).then(res => {
          const msg = res.data.message
          this.$toasted.show(msg, {
            type: res.data.error ? "error" : "success",
            duration: 5000,
            position: "top-center",
            theme: "bubble",
            icon: "mdi-clipboard-check",
            iconPack: "mdi",
          });
          this.closeDialog()
        }).catch(err => {
          this.$toasted.show(err.response.data, {
            type: "error",
            duration: 5000,
            position: "top-center",
            theme: "bubble",
            icon: "mdi-clipboard-check",
            iconPack: "mdi",
          });
        })
      }
      
    },
  },
  computed: {
    ...mapGetters(["userToken"]),
  },
  async created() {
    this.$vloading.show();
    await this.fetchAllUsers(this.userToken).then((res) => {
      this.users = res;
    });
    this.$vloading.hide();
  },
};
</script>

<style>
</style>